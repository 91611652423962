import { PhoneIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { profile_url } from "../../adapters";
import axios from "axios";
import { show } from "../../features/notification/notificationSlice";
import SmsPin from "./SmsPin";

export interface smsDataType {
	step: number;
	loginId: string;
}

export const SmsButton = ({ loginId, callback }: { loginId: string; callback: () => void }) => {
	return (
		<button type="button" className="cta-button-sm dark w-full sm:w-auto" disabled={!loginId} onClick={callback}>
			<PhoneIcon className="h-4 w-4 mr-2" /> Send text
		</button>
	);
};

const SmsFlow = ({ cancelCallback, smsInitialData }: { cancelCallback: () => void; smsInitialData: smsDataType }) => {
	const dispatch = useAppDispatch();

	const [smsData, setSmsData] = useState<smsDataType>(smsInitialData);
	const [phones, setPhones] = useState<string[]>([]);
	const [selectedPhone, setSelectedPhone] = useState<string>();

	useEffect(() => {
		if (smsData.loginId) {
			axios
				.get(profile_url + `api/app/AccountContact/GetPhones?LoginId=${smsData.loginId}`)
				.then((response: any) => {
					if (!response.data.isSuccessful) {
						dispatch(
							show({
								show: true,
								title: "Error",
								message: response.message ?? `No phone numbers were found in our system for this user. Please reset your password via email.`,
								status: "error",
								position: "popover",
								autoHide: 5000,
								confirm: false,
								notificationId: null,
							})
						);
						cancelCallback();
					} else {
						if (response.data.results && response.data.results[0].phones.length > 1) {
							setPhones(response.data.results[0].phones);
						}
					}
				})
				.catch((e) => {
					dispatch(
						show({
							show: true,
							title: "Error",
							message: e.message ?? `There was an error retrieving valid phone numbers for this user. Please reset your password via email.`,
							status: "error",
							position: "popover",
							autoHide: 5000,
							confirm: false,
							notificationId: null,
						})
					);
					cancelCallback();
				});
		} else {
			cancelCallback();
		}
	}, [smsData.loginId]);

	const sendSms = () => {
		if (selectedPhone) {
			axios
				.post(profile_url + `api/app/AccountContact/ForgotPassword/Sms`, { loginId: smsData.loginId, phoneNumber: selectedPhone })
				.then((response: any) => {
					setSmsData({ ...smsData, step: 2 });
				})
				.catch((e) => {
					dispatch(
						show({
							show: true,
							title: "Error",
							message: e.message ?? `There was an error sending the validation code. Please try again or reset your password via email.`,
							status: "error",
							position: "popover",
							autoHide: 5000,
							confirm: false,
							notificationId: null,
						})
					);
					cancelCallback();
				});
		}
	};

	return smsData.step === 1 ? (
		<div className="px-4 2xl:px-8 py-2">
			<div className="px-8 text-center mb-4">
				<p className="text-sm">Please select your mobile phone number from the list. If you do not see your phone number, please contact your association or cancel and reset your password via email.</p>
			</div>
			<div className="mb-4">
				<select id="status" name="status" onChange={(e) => setSelectedPhone(e?.target.value)} value={selectedPhone} className="basic-form-field">
					<option value="">Select Phone Number</option>
					{phones.map((number) => (
						<option key={number} value={number}>
							XXX-XXX-{number}
						</option>
					))}
				</select>
			</div>
			<div className="flex items-center justify-center">
				<button className="cta-button-sm dark" type="submit" disabled={!selectedPhone} onClick={sendSms}>
					<PhoneIcon className="h-4 w-4 mr-2" /> Send Reset Code
				</button>
			</div>
			<div className="text-center">
				<span className="basic-link font-medium text-lg" onClick={cancelCallback}>
					Cancel
				</span>
			</div>
		</div>
	) : smsData?.step === 2 ? (
		<div className="px-4 2xl:px-8 py-2">
			<div className="px-8 text-center mb-4">
				<p className="text-sm">Please enter the pin number sent to your phone. If you did not receive a pin, the phone number selected may not be in our system. Please cancel and try again or reset your password via email.</p>
			</div>
			<div className="mb-4">
				<SmsPin loginId={smsData.loginId} pinLength={6} />
			</div>
			<div className="text-center">
				<span className="basic-link font-medium text-lg" onClick={cancelCallback}>
					Cancel
				</span>
			</div>
		</div>
	) : (
		<></>
	);
};

export default SmsFlow;
