import { push } from "connected-react-router";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks";

function SmsPin({loginId, pinLength = 6}: {loginId: string, pinLength: number}) {
    const dispatch = useAppDispatch();
	const MAX_ITEMS = 6;

	const emptyPin = Array.from(Array(pinLength).keys()).map((item) => ({
		id: Number(item),
		value: "",
	}));

	const [pin, setPin] = useState(emptyPin);
	const [activeNumber, setActiveNumber] = useState(0);
	const autoFocus = useCallback((el) => (el ? el.focus() : null), []);

	const handleOnChange = (event: any) => {
		if (event.target.value) {
            const targetId = event.target.getAttribute('data-id');
			const newPin = pin.map((num) => {
				if (num.id === +targetId) {
					return { id: +targetId, value: event.target.value };
				} else return num;
			});
			setPin(newPin);
			setActiveNumber(activeNumber + 1);
		}
	};

	const handleOnFocus = (event: any) => {
		if (event.target.value) {
            const targetId = event.target.getAttribute('data-id');
			setActiveNumber(+targetId);
            const nextItem = document.getElementById(`item-${event.target.id}`);
            if (nextItem) {
                nextItem.focus();
            }
		}
	};

    useEffect(() => {
        if (pin.filter((p) => p.value).length === pinLength) {
            const validationPin = pin.map((num) => num.value).join("")
            dispatch(push(`/password-reset/sms-${loginId}-${validationPin}`));
        }
    }, [pin]);

	return (
		<div className="flex justify-center flex-column">
			<div className="flex gap-2">
				{Array.from(Array(pinLength).keys()).map((item) => (
					<input className="text-xl w-12 h-12 border-divider rounded-md text-center focus:ring-0" key={item} type="text" data-id={item} id={`item-${item}`} maxLength={1} onChange={handleOnChange} onFocus={handleOnFocus} ref={item === activeNumber ? autoFocus : undefined} />
				))}
			</div>
		</div>
	);
}

export default SmsPin;
